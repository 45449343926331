import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "C:/Users/chatp/Projects/farming-season/doc/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "ระบบเกม"
    }}>{`ระบบเกม`}</h1>
    <hr></hr>
    <h3 {...{
      "id": "การผูกสัญญาตัวละครกับฟาร์ม"
    }}>{`การผูกสัญญาตัวละครกับฟาร์ม`}</h3>
    <p>{`ก่อนที่จะทำเควส หรือทำอะไรบนผืนฟาร์มที่เลือกเข้ามา ผู้เล่นจะต้องผูกสัญญาตัวละครกับผืนฟาร์มนั้นก่อน ถ้าตัวละครที่ผู้เล่นจะผูกสัญญากับฟาร์มนั้น ไม่เคยผูกสัญญามาก่อน
จะไม่มีค่าใช้จ่ายในการผูกสัญญาครั้งแรก ( เฉพาะครั้งแรกครั้งเดียว )`}</p>
    <p><img alt="contract" src={require("./public/images/con-1.png")} /></p>
    <p><img alt="contract" src={require("./public/images/con-2.png")} /></p>
    <blockquote>
      <p parentName="blockquote">{`ฟาร์ม 1 ฟาร์ม สามารถผูกสัญญาตัวละครได้เพียงตัวเดียวเท่านั้น`}</p>
    </blockquote>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      